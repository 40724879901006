/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'templates/Layout';
import FactBoxes from 'modules/FactBoxes';
import Text from 'modules/Text';
import TextAndImage from 'modules/TextAndImage';
import TextAndVideo from 'modules/TextAndVideo';
import NewsletterSlim from 'modules/NewsletterSlim';
import RelatedRibbon from 'modules/RelatedRibbon';
import Timeline from 'modules/Timeline';
import StickyCTA from 'modules/StickyCTA';
import EcospheresNav from 'components/EcospheresNav';
import EcosphereCompanies from 'modules/EcosphereCompanies';
import EcosphereCluster from 'modules/EcosphereCluster';

export const ecosystemPageQuery = graphql`
  query ($slug: String) {
    allDatoCmsDanishStartupsInNumbersPage(sort: { fields: position }) {
      edges {
        node {
          slug
          title
        }
      }
    }
    datoCmsDanishStartupsInNumbersPage(slug: { eq: $slug }) {
      slug
      title
      seo {
        title
        description
        image {
          url
        }
      }
      blocks {
        ... on DatoCmsTextImage {
          model {
            apiKey
          }
          heading
          bodyTextNode {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(placeholder: BLURRED, width: 640)
          }
          reverseOrder
        }
        ... on DatoCmsTextVideo {
          model {
            apiKey
          }
          heading
          bodyTextNode {
            childMarkdownRemark {
              html
            }
          }
          videoEmbedUrl
          reverseOrder
        }
        ... on DatoCmsFactBox {
          model {
            apiKey
          }
          heading
          facts {
            heading
            bodyText
          }
          bigFont15Degrees
        }
        ... on DatoCmsRelatedRibbon {
          model {
            apiKey
          }
          heading
          content
          mostRelatedEcosphere {
            ecoid
          }
        }
        ... on DatoCmsTimeline {
          model {
            apiKey
          }
          milestones {
            year
            label
            bodyTextNode {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        ... on DatoCmsStickyCallToAction {
          model {
            apiKey
          }
          text
          callToAction {
            label
            href
          }
          alternativeCallToActionLabel
        }
        ... on DatoCmsText {
          model {
            apiKey
          }
          textNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsEcosphereCompaniesDatabase {
          model {
            apiKey
          }
          ecosphere {
            ecoid
          }
          additionalInformationNode {
            childMarkdownRemark {
              html
            }
          }
          additionalInformationButtonLabel
        }
        ... on DatoCmsEcosphereCluster {
          model {
            apiKey
          }
          ecosphere {
            ecoid
            clusterName
            clusterWebsite
            clusterFoundedYear
            clusterManager
            clusterJobsUrl
            clusterInvestmentUrl
            clusterLogo {
              width
              height
              blurhash
              url
              gatsbyImageData(width: 400)
              alt
              title
            }
            clusterWhiteLogo {
              width
              height
              blurhash
              url
              gatsbyImageData(width: 400)
              alt
              title
            }
          }
        }
      }
    }
  }
`;

function EcosystemPage({ data }) {
  const { datoCmsDanishStartupsInNumbersPage, allDatoCmsDanishStartupsInNumbersPage } = data;
  const pages = allDatoCmsDanishStartupsInNumbersPage.edges.map((eco) => eco.node);

  const components = datoCmsDanishStartupsInNumbersPage.blocks.map((block, i) => {
    if (block?.model) {
      const { apiKey } = block.model;
      const key = apiKey + i;

      switch (apiKey) {
        case 'text_video':
          return <TextAndVideo {...block} key={key} isHero={i === 0} />;
        case 'sticky_call_to_action':
          return <StickyCTA {...block} key={key} />;
        case 'fact_box':
          return <FactBoxes {...block} key={key} />;
        case 'text_image':
          return <TextAndImage {...block} key={key} />;
        case 'timeline':
          return <Timeline {...block} key={key} />;
        case 'related_ribbon':
          return <RelatedRibbon {...block} key={key} />;
        case 'text':
          return <Text {...block} key={key} />;
        case 'newsletter_slim':
          return <NewsletterSlim {...block} key={key} />;
        case 'ecosphere_companies_database':
          return <EcosphereCompanies {...block} key={key} />;
        case 'ecosphere_cluster':
          return <EcosphereCluster {...block} key={key} />;
        default:
          break;
      }
    }
    return null;
  });

  return (
    <Layout seo={datoCmsDanishStartupsInNumbersPage.seo}>
      <EcospheresNav
        pages={[
          {
            slug: 'all-startups',
            title: 'All',
          },
          ...pages,
          {
            slug: 'about',
            title: 'About',
          },
        ]}
        breadcrumbs={[
          {
            href: '/',
            title: 'Ecosystems',
          },
          {
            href: `/${datoCmsDanishStartupsInNumbersPage.slug}`,
            title: datoCmsDanishStartupsInNumbersPage.title,
          },
        ]}
      />
      {components}
    </Layout>
  );
}

export default EcosystemPage;
